import React, {FormEvent, useEffect, useState} from "react";
import {useApiClient} from "../../network/client/UseApiClient";
import {UsersModel} from "../../models/UsersModel";
import {createUserApiContract, editUserApiContract, getSingleUserApiContract} from "../../services/UserService";
import {NetworkState} from "../../network/dto/ApiContract";
import {Modal, ModalBody} from "react-bootstrap";
import {v4} from "uuid";
import {useToast} from "../../components/toast/ToastManager";
import {Button} from "../../components/button/Button";

export type Payload = {
    id?: string,
    username?: string,
    fullname?: string,
    quivrId?: string,
    password?: string
}

const AddEditUserModal = (props: {
    show: boolean,
    onCancel: () => void,
    onSuccess: () => void,
    defaultValue?: string
}) => {
    const [payload, setPayload] = useState<Payload>({
        username: "",
        fullname: "",
        quivrId: "",
        password: ""
    });
    const [users, usersNetworkState, usersError, fetchUsers] = useApiClient<UsersModel>();
    const [isPassVisible, setIsPassVisible] = useState<boolean>(false);
    // const [_, addUserNetworkState, addUserError, addUser] = useApiClient<UsersModel>();
    const [_, addUserNetworkState, addUserError, addUser] = useApiClient<any>();
    const toast = useToast();

    useEffect(() => {
        if (props.defaultValue && props.show) {
            fetchUsers(getSingleUserApiContract(props.defaultValue)).then(()=>{

            }).catch((err)=>{
                if (err.response.status !== 403)
                    toast.show({id: v4(), title:err.response.data.message, duration: 3000, isSuccess: false});
            });
        }
    }, [props.defaultValue]);

    useEffect(() => {
        if (addUserNetworkState === NetworkState.Loaded) {
            props.onSuccess();
        }
    }, [addUserNetworkState]);

    useEffect(() => {
        if (props.defaultValue) {
            setPayload({...users})
        } else {
            setPayload({});
        }
    }, [users])

    useEffect(() => {
        if (props.show) {
            setPayload({});
        }
    }, [props.show]);
    const handleInput = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        setPayload({...payload, [name]: value});
    }

    const handleForm = (e: FormEvent, payload: Payload) => {
        e.preventDefault();
        if (props.defaultValue) {
            addUser(editUserApiContract(props.defaultValue, payload)).then((res)=>{
                toast.show({id: v4(), title: "User Updated Successfully", duration: 3000});
            }).catch(err => {
                if(err.response.status !== 403){
                    toast.show({id: v4(), title: err.response.data.message, duration: 3000, isSuccess: false});
                }
            })
            setPayload({});
        } else {
            addUser(createUserApiContract(payload)).then((res)=>{
                toast.show({id: v4(), title: res.message, duration: 2000});
            }).catch(err => {
                if (err.response.status !== 403)
                    toast.show({id: v4(), title: err.response.data.message, duration: 2000, isSuccess: false});
            });
            setPayload({});
        }
    }

    const onClose = () => {
        props.onCancel();
        setPayload({});
    }

    return <React.Fragment>
        <Modal show={props.show} onHide={onClose} centered>
            <Modal.Header closeButton className="border-0 pb-0">
                <h5>{props?.defaultValue ? "Update User" : "Add User"}</h5>
            </Modal.Header>
            <ModalBody className="modal_body mx-2">
                <form onSubmit={(e) => handleForm(e, payload)} id={`create-user-form`}>
                    <div className="form-group">
                        <label htmlFor="exampleInputName">Username <span className='text-danger'>*</span></label>
                        <input type="email" className="form-control" value={payload.username || ''} id="exampleInputName"
                               aria-describedby="nameHelp" onChange={handleInput} placeholder="Enter Username"
                               name="username" required/>
                    </div>
                    <br/>
                    <div className="form-group">
                        <label htmlFor="exampleInputName">Full Name <span className='text-danger'>*</span></label>
                        <input type="text" className="form-control" value={payload.fullname || ''} id="exampleInputName"
                               aria-describedby="nameHelp" onChange={handleInput} placeholder="Enter Fullname"
                               name="fullname" required/>
                    </div>
                    {/*<div className="form-group mt-2">*/}
                    {/*    <label htmlFor="exampleInputQuivrId">Quivr Id <span className='text-danger'>*</span></label>*/}
                    {/*    <input type="text" className="form-control" value={payload.quivrId || ''} id="exampleInputQuivrId"*/}
                    {/*           aria-describedby="nameHelp" onChange={handleInput} placeholder="Quivr Id"*/}
                    {/*           name="quivrId" required/>*/}
                    {/*</div>*/}
                    {/*<div className="form-group mt-2">*/}
                    {/*    <label className={'small'}><b>Profile Picture</b></label>*/}
                    {/*    <ImageUpload clientId={''}  projectId={''} onDone={()=> {*/}
                    {/*    }}/>*/}


                    {/*</div>*/}
                    <br/>
                    {
                        props.defaultValue ? '' :
                            <div className="form-group">
                                <label htmlFor="exampleInputName">Password <span
                                    className='text-danger'>*</span></label>
                                <div className="input-group">
                                    <input
                                        type={isPassVisible ? "text" : "password"}
                                        className="form-control"
                                        value={payload.password || ''}
                                        id="exampleInputName"
                                        aria-describedby="nameHelp"
                                        onChange={handleInput}
                                        placeholder="Password"
                                        name="password"
                                        required
                                    />
                                    <button
                                        className="btn btn-outline-secondary"
                                        type="button"
                                        onClick={() => setIsPassVisible(!isPassVisible)}
                                    >
                                        <i className={`bi ${isPassVisible ? 'bi-eye' : 'bi-eye-slash'}`}></i>
                                    </button>
                                </div>
                            </div>
                    }

                    <div className={'d-flex justify-content-between mt-3'}>
                        <Button className="btn btn-outline-primary me-2" onClick={onClose}>Cancel</Button>
                        <Button className="btn btn-primary me-2" loaderText={'...saving'} loading={addUserNetworkState == NetworkState.Loading}
                                type={"submit"}>{props?.defaultValue ? "Update" : "Create"}</Button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    </React.Fragment>
};

export default AddEditUserModal;
