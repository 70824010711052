import React, {Fragment, useState} from 'react';
import Img from '../../../../../../../assets/icons/Img.svg';
import menu from '../../../../../../../assets/icons/menu.svg';
import './documentListCard.scss';
import rvtIcon from '../../../../../../../assets/icons/rvt.png';
import pdfIcon from '../../../../../../../assets/icons/pdf.png';
import docIcon from '../../../../../../../assets/icons/doc_icon.png';
import pin from '../../../../../../../assets/icons/tabler_pin-filled.png'
import {DocumentMember, Documents} from "../../../../../../../models/Documents";
import moment from "moment/moment";
import {DropdownButton, DropdownItem} from "react-bootstrap";
import {useApiClient} from "../../../../../../../network/client/UseApiClient";
import {
    deleteDocument,
    downloadDocument,
    getAllDocumentUser,
    pinDocument,
    softDeleteDocument,
    unArchive,
    unPinDocument
} from "../../../../../../../services/DocumentService";
import {useFileUtils} from "../../../../../../../utils/FIleUtils";
import {AddMemberToDocument} from "./addMemberModal/AddMember";
import ConfirmationModal from "../../../../../../../components/confirmationDialog/ConfirmationModal";
import {MemberList} from "./memberList/MemberList";
import {useToast} from "../../../../../../../components/toast/ToastManager";
import {v4} from "uuid";
import {NetworkState} from "../../../../../../../network/dto/ApiContract";
import {DocumentModal} from "../documentModal/DocumentModal";
import {Spinner} from "../../../../../../../components/spinner/Spinner";

interface ProjectCardProps {
    defaultValue?: Documents;
    onShowDoc: () => void;
    onSuccess: (shouldRefresh?: boolean) => void;
    onRunModelChecker: (doc: {docId?: string, docName?: string}) => void
}

const DocumentListCard: React.FC<ProjectCardProps> = ({defaultValue, onShowDoc, onSuccess, onRunModelChecker}) => {
    const [data, setData] = useState(defaultValue);
    const [showMemberModal, setShowMemberModal] = useState<boolean>(false);
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [_docMembers, getDocMembersNetworkState, getDocError, getAllDocMembers] = useApiClient<any>();
    const [docMembers, setDocMembers] = useState<any[]>(defaultValue?.members||[]);
    const [option, setOption] = useState<any[]>([{value: 'archive', label: 'Archive'}, {
        value: 'pin',
        label: 'Pin'
    }, {value: 'delete', label: 'Delete'}]);

    const {downloadFile} = useFileUtils();
    const toastManager = useToast();
    const [pinDoc, pinDocNetworkState, error, pinDocs] = useApiClient<Documents>();
    const [unPinDoc, unPinDocNetworkState, unPinError, unPinDocs] = useApiClient<Documents>();
    const [removeDoc, removeDocNetworkState, removeError, removeDocs] = useApiClient<Documents>();
    const [archiveDoc, archiveDocNetworkState, archiveError, archiveDocs] = useApiClient<Documents>();
    const [unArchiveDoc, unArchiveDocNetworkState, unArchiveError, unArchiveDocs] = useApiClient<Documents>();
    const [downloadDoc, downloadDocNetworkState, downloadError, downloadDocs, docRes] = useApiClient<Blob>();
    const toast = useToast();


    const pinTheDocument = (id: string) => {
        pinDocs(pinDocument(id)).then(res => {
            setData(presState => ({...presState, pinned: true}));
        });
    }
    const unPinTheDocument = (id: string) => {
        unPinDocs(unPinDocument(id)).then(res => {
            setData(presState => ({...presState, pinned: false}));
        });
    }
    const removeDocument = (id: string) => {
        removeDocs(deleteDocument(id)).then(()=>{
            toast.show({id: v4(), title: 'Deleted Successfully', duration: 2000});
            onSuccess(true);
        }).catch(err => {
            if (err.response.status !== 403) {
                toast.show({id: v4(), title: err.response.data?.message || "Fail to delete", duration: 2000, isSuccess: false});
            }
        })

    }
    const archiveDocument = (id: string) => {
        archiveDocs(softDeleteDocument(id)).then(res => {
            setData(presState => ({...presState, active: false}));
        });
    }
    const unArchiveTheDocument = (id: string) => {
        unArchiveDocs(unArchive(id)).then(res => {
            setData(presState => ({...presState, active: true}));
        })
    }
    const editTheDocument = (id: string) => {
        setShowEditDialog(true);
    }

    const downloadTheDocument = (id: string) => {
        downloadDocs(downloadDocument(id)).then(res => {
            downloadFile("", res)
        }).catch(err => {
            if (err.response.status !== 403)
                toastManager.show({
                    id: v4(),
                    title: `Error downloading document ${err.response.status === 404 ? ': Not Found' : ''}`,
                    duration: 5000,
                    isSuccess: false
                })
        })
    }

    const getIndexStateColor = (state?: string) => {
        switch (state?.toLowerCase()) {
            case 'indexing':
                return 'text-warning';
            case 'not_indexed':
                return 'text-warning';
            case 'indexing_failed':
                return 'text-danger';
            case 'indexed':
                return 'text-success';
            default:
                return 'text-secondary';
        }
    }
    const getIndexStateLabel = (state?: string) => {
        switch (state?.toLowerCase()) {
            case 'indexing':
                return 'Indexing';
            case 'not_indexed':
                return 'Not Indexed';
            case 'indexing_failed':
                return 'Indexing Failed';
            case 'indexed':
                return 'Indexed';
            default:
                return 'Unknown';
        }
    }

    const getFileIcon = (ext: string) => {
        const tokens = ext.toLowerCase().split('.');
        switch (tokens[tokens.length-1]) {
            case 'rvt':
                return rvtIcon;
            case 'pdf':
                return pdfIcon;
            case 'doc':
            case 'docx':
                return docIcon;
            default:
                return Img;
        }
    }

    return (
        <Fragment>
            <tr onClick={() => {
            }}>

                <td>{data?.pinned === true ? <img src={pin}/> : ''}</td>
                <td className={`${data?.active === false ? 'bg-light' : ''}`} onClick={() => onShowDoc()}>
                    <div className={`d-flex align-items-center`}>
                        <img src={getFileIcon(data?.name||'')} className={'me-2 fs-4'}
                             style={{
                                 display: 'flex',
                                 fontSize: '18px',
                                 fontWeight: '25px', height: '30px', width: '30px'
                             }}>
                        </img>
                        <p className={'mb-0 text-decoration-underline cursor-pointer'}>{data?.name ? data.name : 'PDF file name'}</p>
                    </div>
                </td>
                <td className={`${data?.active === false ? 'bg-light' : ''}`}>{data?.createdDate ?
                    <span>{moment(data?.createdDate).format('MMM  DD')}</span> : '-'}</td>
                <td className={`${data?.active === false ? 'bg-light' : ''}`}>{data?.origin ? data?.origin : 'Chat'}</td>
                <td className={`${data?.active === false ? 'bg-light' : getIndexStateColor(data?.indexStatus)}`}>{data?.indexStatus ? getIndexStateLabel(data?.indexStatus) : 'Unknown'}</td>
                <td className={`${data?.active === false ? 'bg-light' : ''}`}><span
                    className={'d-flex justify-content-center align-item-center'}>
                 {docMembers && docMembers.slice(0, 3).map((member: DocumentMember, index: number) => (
                     <MemberList key={index} data={member} />
                 ))}

                    {docMembers && (data?.members?.length || 0) >= 4 ? <p className={'user-filter-chip d-flex justify-content-center align-items-center editIconMember position-relative bg-primary text-white'}>
                       <small className={'text-center'} onClick={() => setShowMemberModal(true)}>+{(data?.members?.length || 0) - 3}</small>

                </p>  : ''}
                    <p className={'user-filter-chip d-flex justify-content-center align-items-center editIconMember position-relative me-0'}>
                                 {/*{(data?.members?.length || 0) > 4 ? <span*/}
                        {/*    className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">*/}
                        {/*  {(data?.members?.length || 0) - 3}*/}
                        {/*         </span> : ''}*/}

                        <small className={'text-center text-primary'} onClick={() => setShowMemberModal(true)}><i
                            className="bi bi-person-add fs-6"></i>
                   </small>

                </p>
                    </span></td>
                <td className={`${data?.active === false ? 'bg-light' : ''}`}>
                    {downloadDocNetworkState === NetworkState.Loading ?
                        <Spinner hw={24} />
                        :
                        <DropdownButton
                            drop={`start`}
                            title={<img src={menu} onClick={() => ''}/>}
                            className={`text-secondary`}>
                            {data?.active === true ?
                                <DropdownItem onClick={() => archiveDocument(data?.id || '')}><span
                                    className={`bi bi-archive me-2`}/> Archive</DropdownItem>

                                :
                                <DropdownItem onClick={() => unArchiveTheDocument(data?.id || '')}><span
                                    className={`bi bi-archive me-2`}/> Un Archive</DropdownItem>

                            }
                            {data?.pinned === true ?
                                <DropdownItem onClick={() => unPinTheDocument(data?.id || '')}><span
                                    className={`bi bi-pin me-2`}/> Unpin</DropdownItem>
                                :
                                <DropdownItem onClick={() => pinTheDocument(data?.id || '')}><span
                                    className={`bi bi-pin me-2`}/> Pin</DropdownItem>

                            }
                            {data?.name?.toLowerCase().endsWith('rvt') ?
                                <DropdownItem onClick={() => onRunModelChecker({docId: data?.id, docName: data?.name})}><span
                                    className={`bi bi-play me-2`}/>Run model</DropdownItem>
                                : null
                            }
                            <DropdownItem onClick={() => editTheDocument(data?.id || '')}><span
                                className={`bi bi-pencil  me-2`}/> Edit</DropdownItem>
                            <DropdownItem onClick={() => downloadTheDocument(data?.id || '')}><span
                                className={`bi bi-download me-2`}/> Download</DropdownItem>

                            <DropdownItem className={`text-danger`} onClick={() => {
                                setShowDelete(true);
                                onSuccess();
                            }}><span className={`bi bi-trash me-2 text-danger`}/> Delete</DropdownItem>
                        </DropdownButton>}

                </td>

            </tr>
            <ConfirmationModal title={'Delete Document'} message={'Are You sure you want to delete this?'}
                               onSubmit={() => {
                                   removeDocument(data?.id || '');
                                   setShowDelete(false);
                               }} onCancel={() => setShowDelete(false)} show={showDelete} btnText={'Delete'}/>


            <AddMemberToDocument show={showMemberModal} onCancel={() => setShowMemberModal(false)} onSuccess={async () => {
                // setShowMemberModal(false);
                onSuccess(true);
                const members = await getAllDocMembers(getAllDocumentUser(defaultValue?.id || ""));
                setDocMembers(members)

            }} id={data?.id}/>


            <DocumentModal show={showEditDialog} onCancel={()=>setShowEditDialog(false)} onSuccess={async()=>{
                setShowEditDialog(false);
            }} defaultValue={data || ''}/>

        </Fragment>
    );
}

export default DocumentListCard;