import './documentModal.scss';
import React, {FormEvent, useEffect, useState} from 'react';
import {Modal, ModalBody, ModalFooter} from "react-bootstrap";
import AttachmentComponent from "../../../../../../../components/attachmentComponent/AttachmentComponent";
import {useApiClient} from "../../../../../../../network/client/UseApiClient";
import {Documents} from "../../../../../../../models/Documents";
import {useInputHandler} from "../../../../../../../hooks/UseInputHandler";
import {getSingleDocument, updateDocument, uploadDocument} from "../../../../../../../services/DocumentService";
import {useParams} from "react-router";
import {NetworkState} from "../../../../../../../network/dto/ApiContract";
import {useToast} from "../../../../../../../components/toast/ToastManager";
import {v4} from "uuid";
import {Button} from "../../../../../../../components/button/Button";
import PermissionConfirmationModal
    from "../../../../../../../components/PermissionConfirmationDialog/PermissionConfirmationModal";
import ZipIndexingConfirmationModal
    from "../../../../../../../components/ZipIndexingConfirmationDialog/ZipIndexingConfirmationModal";
import {updateClient} from "../../../../../../../services/ClientService";

export const DocumentModal = (props: {
    show: boolean,
    onCancel: (shouldRefresh?: boolean) => void,
    onSuccess: (shouldRefresh?: boolean) => void,
    defaultValue?: any
}) => {
    const {payload, handleChange,setValue,setData} = useInputHandler<any>({});
    const [documents, documentsNetworkState, error, postDocuments] = useApiClient<Documents[]>();
    const [documentUpdate, documentUpdateNetworkState, errorUpdateDocument, updateDocuments] = useApiClient<Documents[]>();
    const [documentDetail, documentDetailNetworkState, errorDocumentDetail, getDocumentDetail] = useApiClient<Documents[]>();
    const[selectedFile,setSelectedFile]=useState<File|null>(null);
    const params = useParams<{projectId: string}>();
    const toast = useToast();
    const [showPermissionConfirmation, setShowPermissionConfirmation] = useState<boolean>(false);

    useEffect(()=> {
        if (documentsNetworkState === NetworkState.Loaded) {
            props.onSuccess(true);
            toast.show({id: v4(), title: "Document Uploaded Successfully!",duration: 2000})
        } else if (documentsNetworkState === NetworkState.Failed && error?.response?.status === 413) {
            toast.show({id: v4(), title:'Max file size limit of 15MB exceeded.', isSuccess: false});
        }
    }, [documentsNetworkState])


    useEffect(() => {
      if(props?.defaultValue)  {
            getSingleDocumentDetail(props?.defaultValue?.id ||'')
        }
    }, []);


    const addDocument = () => {
        if (selectedFile)
            postDocuments(uploadDocument(params?.projectId || '', selectedFile, payload?.name||null, payload?.description||null)).catch(err=> {
                if (err.response.status !== 403)
                    toast.show({id: v4(), title: err.response.data.message, duration: 5000, isSuccess: false})
                // toast.show({id: v4(), title: `Error: ${err.toString()}`, duration: 5000, isSuccess: false})
            })
    }

    const submit=()=>{
        if(props?.defaultValue){
            updateDocuments(updateDocument(props.defaultValue?.id || '', payload.name)).then(res=> {
                props.onSuccess(true);
                toast.show({id: v4(), title: "Document Updated Successfully", duration: 2000});
                setData('');
            }).catch(err=>{
                if(err.response.status !== 403){
                    toast.show({id: v4(), title: err.response.data.message, duration: 2000, isSuccess: false})
                }
            })
        }else{
            addDocument();
    }};


    const getSingleDocumentDetail=(id:string)=>{
        getDocumentDetail(getSingleDocument(id)).then((res:any)=>{
            setData(res)
        })
    }


    const submitForm = (event: FormEvent) => {
        event.preventDefault();
        const extParts = selectedFile?.name.split(".");
        if(extParts && extParts[extParts.length-1]?.toLocaleLowerCase() === 'zip'){
          setShowPermissionConfirmation(true)
        }else{

            submit();
        }
    }

    return <React.Fragment>
        <Modal show={props.show} onHide={props.onCancel} centered style={{}} className={'modal documentModal'}>
            <Modal.Header closeButton className="border-0 pb-0">
                <h6 className={'fw-bolder'}>{props.defaultValue ? "Update" : "Upload New Document"}</h6>
            </Modal.Header>
            <ModalBody className="modal-content rounded-0 border-0">
                <form id={`form_file_upload`} className={'flex-fill overflow-y-auto'} onSubmit={submitForm}>
                    {
                        props.defaultValue ?
                            <div className="form-group mt-2">
                                <label className={'small text-secondary'}><b>Document Name</b> (optional) </label>
                                <input type="text" className="form-control rounded-0" id="exampleInputName" aria-describedby="nameHelp"
                                       onChange={handleChange} value={payload.name || ''} name="name"/>

                            </div>:
                            <>
                                <div className="form-group mt-2">
                                    <label className={'fw-bold small'}>Choose document <span className='text-danger'>*</span></label>
                                    <AttachmentComponent onChange={setSelectedFile} required/>
                                </div>
                                <div className="form-group mt-2">
                                    <label className={'small text-secondary'}><b>Document Name</b> (optional) </label>
                                    <input type="text" className="form-control rounded-0" id="exampleInputName" aria-describedby="nameHelp"
                                           onChange={handleChange} value={payload.name || ''} name="name"/>

                                </div>
                                <div className="form-group mt-2">
                                    <label className={'small text-secondary'}><b>Description</b> (optional)</label>
                                    <textarea rows={2} className="form-control rounded-0" id="exampleInputName" aria-describedby="nameHelp"
                                              onChange={handleChange} value={payload.description || ''} name="description"/>


                                </div>
                            </>

                    }


                </form>
            </ModalBody>
            <ModalFooter className={'d-flex justify-content-end border-0'}>
                <Button type={'button'} className={'btn btn-outline-primary py-0 px-3 small fw-bold'} onClick={() => props.onCancel(false)}>Cancel</Button>
                <Button type={'submit'} loading={documentsNetworkState === NetworkState.Loading} form={`form_file_upload`} loaderText={`...uploading`} className={'btn btn-primary py-0 px-3 small'} onClick={() => props.onSuccess}>{props.defaultValue ? 'Update': "Upload"}</Button>
            </ModalFooter>
        </Modal>

        <ZipIndexingConfirmationModal title={`Permissions`} message={`Are you sure? All files present in this zip will get indexed .`} onSubmit={()=> {
            setShowPermissionConfirmation(false);
            addDocument();
        }} onCancel={()=> {setShowPermissionConfirmation(false)}} show={showPermissionConfirmation} btnText={'Grant'} />
    </React.Fragment>
}