import sortOrder from '../../../../../../assets/icons/sort_order.svg';
import './projectDocuments.scss'
import React, {Fragment, useEffect, useState} from "react";
import upload from '../../../../../../assets/icons/upload.svg';
import {DocumentModal} from "./documentModal/DocumentModal";
import {SyncModal} from "./syncModal/snycModal";
import {useApiClient} from "../../../../../../network/client/UseApiClient";
import {Documents} from "../../../../../../models/Documents";
import {getAllDocuments} from "../../../../../../services/DocumentService";
import {useParams} from "react-router";
import {NetworkState} from "../../../../../../network/dto/ApiContract";
import {Spinner} from "../../../../../../components/spinner/Spinner";
import DocumentListCard from "./documentList/DocumentListCard";
import {EmptyState} from "../../../../../../components/emptyState/EmptyState";
import {Data} from "../../../../../../network/dto/Data";
import {DocView} from "../../../../../../components/doc/DocView";
import {ModelCheckerModal} from "../../../../../../components/modelchecker/ModelCheckerModal";

export const ProjectDocuments = () => {
    const [showModal,setShowModal]=useState<boolean>(false);
    const [modelDoc,runModelChecker]=useState<{docId?: string, docName?: string}>();
    const [showSyncModal,setShowSyncModal]=useState<boolean>(false);
    const [documents, documentsNetworkState, error, fetchDocuments] = useApiClient<Data<Documents[]>>();
    const params=useParams<{projectId: string}>();
    const [showProgress, setShowProgress] = useState<boolean>(true);
    const [currentVersion, setCurrentVersion] = useState<number | undefined>(undefined);
    const [newVersion, setNewVersion] = useState<number | undefined>(undefined);
    const [showRefreshMessage, setShowRefreshMessage] = useState(false);
    const [showDocViewer, setShowDocViewer] = useState<Documents|undefined>();
    useEffect(()=> {
        fetchDocuments(getAllDocuments(+(params.projectId || '')))
            .then((fetchedDocuments) => {
                setShowProgress(false);
                setCurrentVersion(fetchedDocuments?.version);
                setNewVersion(fetchedDocuments?.version)
            })
            .catch((error) => {
            });

        /*const intervalId = setInterval(async () => {
            try {
                const fetchedDocuments = await fetchDocuments(getAllDocuments(+(params.projectId || '')));
                if (newVersion !== fetchedDocuments?.version) {
                    setNewVersion(fetchedDocuments?.version);
                    console.log('New Version', fetchedDocuments?.version);
                }
            } catch (error) {
                console.error('Error fetching new version:', error);
            }
        }, 10000);// 1 minute = 60,000 milliseconds

        return () => {
            clearInterval(intervalId);
        };*/

    },[params?.projectId]);

    const checkVersion = (versionFirst: number, versionSecond: number) => {
        if (versionFirst !== versionSecond) {
            setShowRefreshMessage(true);
        } else {
            setShowRefreshMessage(false);
        }
    };

    useEffect(() => {
        checkVersion(currentVersion || 0, newVersion || 0);
    }, [currentVersion, newVersion]);



//     const intervalId = setInterval(() => {
//         fetchDocuments(getAllDocuments(+(params.projectId ||'')))
//     }, 60000); // 1 minute = 60,000 milliseconds
//     return () => {
//         clearInterval(intervalId);
//     };
// }, []);

    return <div className={'mt-1'}>
        <div className={`documentList`}>
            <div className={`d-flex`}>
                <button className={`btn btn-outline-primary me-2`} style={{ pointerEvents: 'none', opacity: 0.5 }}>Sync</button>
                <button className={`btn btn-primary d-flex`} onClick={()=>setShowModal(true)}>
                    <img src={upload} className={'uploadIcon me-1'}/>
                    Upload</button>
                <div className={`ms-3 d-flex align-items-center`} style={{ pointerEvents: 'none', opacity: 0.5 }}>
                    <img src={sortOrder} className={'me-2'}/>
                    <small>Sort by: Most Recent</small>
                </div>
            </div>
            <div>
                <div className={'d-flex justify-content-center'}>
                    {showRefreshMessage && (
                        <div className={'cardIntegration overflow-hidden shadow border p-3 mb-3'} style={{ color: 'red' }}>
                            Changes in documents from server, please refresh.
                        </div>
                    )}
                </div>
            </div>


            {documentsNetworkState=== NetworkState.Loading && showProgress?<div className={`w-100 d-flex justify-content-center`}>
                <Spinner/>
            </div>:<div className={'documents mt-4'}>

                {documents?.data.length ? <table className={`text-center w-100 border-0`}>
                    <thead>
                    <td></td>
                    <td><small className={'ms-0'}>Name</small></td>
                    <td><small>Last modified</small></td>
                    <td><small>Origin</small></td>
                    <td><small>Status</small></td>
                    <td><small>Members</small></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </thead>

                    <tbody>
                    <Fragment>{Array.isArray(documents.data) &&
                        documents.data.map((detail: Documents | any) => {
                            if (Array.isArray(detail)) {
                                return detail.map((innerDetail: Documents) => (
                                    <DocumentListCard defaultValue={innerDetail} key={innerDetail.id} onShowDoc={()=> setShowDocViewer(innerDetail)} onRunModelChecker={runModelChecker} onSuccess={()=>{
                                        fetchDocuments(getAllDocuments(+(params.projectId||'')))
                                    }}/>
                                ));
                            } else {
                                return <DocumentListCard defaultValue={detail} key={detail.id} onRunModelChecker={runModelChecker} onShowDoc={()=> setShowDocViewer(detail)} onSuccess={()=>{
                                    fetchDocuments(getAllDocuments(+(params.projectId||'')))
                                }}/>;
                            }
                        })
                    }

                    </Fragment>
                    </tbody>
                </table> : <div className={'container bg-white h-100'} style={{height:'100%'}}> <EmptyState/> </div>}

            </div>}
        </div>

        <DocumentModal show={showModal} onCancel={()=>setShowModal(false)} onSuccess={()=>{
            setShowModal(false);
            // if (refresh) {
            fetchDocuments(getAllDocuments(+(params.projectId||'')))
            // }
        }}/>
        <DocView key={`doc_viewer`} file={showDocViewer?{url: showDocViewer?.url||'', name: showDocViewer?.name||'', id: showDocViewer?.id||''}:undefined} onHide={()=> setShowDocViewer(undefined)} />
        <SyncModal show={showSyncModal} onCancel={()=>setShowSyncModal(false)} onSuccess={()=>setShowSyncModal(false)}/>
        <ModelCheckerModal doc={modelDoc} onHide={()=> runModelChecker(undefined)} />
    </div>
}