import React from 'react';
import './App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {Index} from "./pages/Index";
import {DefaultProjectProvider} from "./hooks/DefaultProject";

function App() {
  return (
    <div className="h-100">
        <DefaultProjectProvider>
            <Index />
        </DefaultProjectProvider>
    </div>
  );
}

export default App;
