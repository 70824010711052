import React, {Fragment, useState} from 'react';
import './projectCard.scss';
import {Project} from "../../../../../models/Project";
import projectPlaceholderImg from '../../../../../assets/images/project_placeholder.png';
import './../projectCardList.scss';
import moment from 'moment';
import {DropdownButton, DropdownItem} from "react-bootstrap";
import menu from "../../../../../assets/icons/menu.svg";
import ConfirmationModal from "../../../../../components/confirmationDialog/ConfirmationModal";
import {useApiClient} from "../../../../../network/client/UseApiClient";
import {DeleteProjectApiContract} from "../../../../../services/ProjectService";
import {useNavigate} from "react-router-dom";

interface ProjectCardProps {
    data?: Project;
    onClick: ()=> void;
    onDelete:()=>void;
    isDefault: boolean,
    idx: number
}

const ProjectListCard: React.FC<ProjectCardProps> = ({data, onClick,onDelete, isDefault, idx}) => {
    const [showConfirmationDialog,setShowConfirmationDialog]=useState<boolean>(false);
    const [defaultProject,setDefaultProject]=useState<boolean>(false);

    const [project,projectNetworkState, error, deleteProject] = useApiClient<Project>();
    const navigate = useNavigate();

    const removeProject=(id?:string)=>{
      deleteProject(DeleteProjectApiContract(id)).then(()=> {
          onDelete()
      })
    }
    return (
        <Fragment>
            <tr>
                <td className={''} onClick={()=> {
                    onClick();
                }} style={{cursor:'pointer'}}>
                    <div className={`d-flex align-items-center`}>
                        {
                            data?.profilePicture ?
                                <img src={data?.profilePicture} className={'imgThumbnail me-2'}>
                                </img> :
                                <img src={projectPlaceholderImg} className={'imgThumbnail me-2'}/>

                        }

                        <small className={`fw-bold`}>{data?.name}</small>
                    </div>
                </td>

                <td className={'user-profile d-flex justify-content-center align-items-center'}><img src={'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cHJvZmlsZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60'} className={'imgThumbnail'} >

                </img></td>
                <td>{moment(data?.startDate).format('MMM  DD')} - {moment(data?.finishDate).format('MMM DD')}</td>
                {data?.status === 'Done' ?
                    <td style={{color:'#348114', fontSize:'18px',}}>{data?.status}</td> : <td style={{
                        color:'#DB8C30',
                        fontSize:'18px',
                    }}>{data?.status}</td>}
                <td>{data?.notes || '--'}</td>
                <td><span className="">{data?.priority || '--'}</span></td>
                <td>{moment(data?.lastUpdated).format('MMM  DD')}</td>
                <td className={''}>
                    <div className={'border-0 '}>
                        <DropdownButton
                            drop={`start`}
                            title={<img src={menu} onClick={() => ''}/>}
                            className={`text-white`}>
                            <DropdownItem className={``} onClick={()=>navigate(`/manage/project/edit-project/${data?.id}`)}><span className={`bi bi-pencil me-2`}/>Edit</DropdownItem>
                            <DropdownItem className={``} onClick={(evt)=>{evt.stopPropagation(); setDefaultProject(!defaultProject)}}><span className={`bi bi-check-circle me-2`}/> Set as default</DropdownItem>
                            <DropdownItem className={`text-danger`} onClick={()=>setShowConfirmationDialog(true)}><span className={`bi bi-trash me-2 text-danger`}/> Delete</DropdownItem>
                        </DropdownButton></div>

                </td>
            </tr>
            <ConfirmationModal title={'Delete Client'} message={'Are you sure you want to delete this Client?'} onSubmit={()=> {
                removeProject(data?.id);
                setShowConfirmationDialog(false)
            }} onCancel={()=>setShowConfirmationDialog(false)} show={showConfirmationDialog} btnText={'Delete'}/>
        </Fragment>

    );
}

export default ProjectListCard;