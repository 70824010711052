import {FormDataUtil} from "./FormDataUtil";
import {Buffer} from "buffer";

const Autodesk = window.Autodesk;
export const APP_URL = 'https://developer.api.autodesk.com';

/**
 * Generates access token for viewing models in the Model Derivative service.
 * @param {(string, number) => void} callback Function that will be called with generated access token and number of seconds before it expires.
 */
export function getAccessToken(callback: any) {
    const username= 'WzOngmBVMhFplxDGZc1S4vZkHlcN6fWeH7S8yOY9kiaOKqfF';
    const password= 'g0Air0ucuu7MhJCwtOGW6Pn6pUylYhklNlUA5NcQhfDVxgPAbuA6vEadZ9LwKrAw';
    const data = new FormDataUtil({'grant_type': 'client_credentials', 'scope': 'data:read data:write data:create viewables:read bucket:read bucket:create'}).getUrlEncodedFormData();
    console.log("FD", data)
    fetch(APP_URL + '/authentication/v2/token', {method: 'POST', headers: {'Authorization': 'Basic '+Buffer.from(`${username}:${password}`).toString('base64'), 'Content-Type': 'application/x-www-form-urlencoded'},body: data})
        .then(resp => resp.ok ? resp.json() : Promise.reject(resp))
        .then(credentials => callback(credentials.access_token, credentials.expires_in))
        .catch(err => {
            console.error(err);
            alert('Could not get access token. See console for more details.');
        });
}

/**
 * Initializes the runtime for communicating with the Model Derivative service, and creates a new instance of viewer.
 * @async
 * @param {HTMLElement} container Container that will host the viewer.
 * @param {object} config Additional configuration options for the new viewer instance.
 * @returns {Promise<Autodesk.Viewing.GuiViewer3D>} New viewer instance.
 */
export function initViewer(container: HTMLElement, config: any) {
    return new Promise(function (resolve, reject) {
        Autodesk.Viewing.Initializer({ getAccessToken }, function () {
            const viewer = new Autodesk.Viewing.GuiViewer3D(container, config);
            viewer.start();
            resolve(viewer);
        })
    });
}

/**
 * Lists all models available for viewing.
 * @async
 * @returns {Promise<{ name: string, urn: string }>} List of models.
 */
export function listModels() {
    return fetch(APP_URL + '/api/models')
        .then(resp => resp.ok ? resp.json() : Promise.reject(resp));
}

/**
 * Loads specific model into the viewer.
 * @param {Autodesk.Viewing.GuiViewer3D} viewer Target viewer.
 * @param {string} urn URN of the model in the Model Derivative service.
 */
export function loadModel(viewer: any, urn: string, onDocLoaded: (doc: any)=> void) {
    Autodesk.Viewing.Document.load(
        'urn:' + urn,
        async (doc: any) => {
            await viewer.loadDocumentNode(doc, doc.getRoot().getDefaultGeometry());
            onDocLoaded(doc);
        },
        (code: string, message: string, errors: any) => {
            console.error(code, message, errors);
            alert('Could not load model. See console for more details.');
        },
        (message: any) => {
            alert("loaded")
        },
    );
}