import React, {FormEvent, Fragment, useEffect, useState} from "react";
import './addNewProject.scss'
import {useInputHandler} from "../../../../../hooks/UseInputHandler";
import {Project} from "../../../../../models/Project";
import {Button} from "../../../../../components/button/Button";
import {NetworkState} from "../../../../../network/dto/ApiContract";
import {useApiClient} from "../../../../../network/client/UseApiClient";
import {useNavigate} from "react-router-dom";
import backArrow from "../../../../../assets/icons/back_arrow.svg";
import {createNewProject, GetSingleProject, updateProject} from "../../../../../services/ProjectService";
import {useToast} from "../../../../../components/toast/ToastManager";
import {v4} from "uuid";
import {useLocation, useParams} from "react-router";
import moment from "moment";
import {UploadFiles} from "../../../../../components/upload/files/FilesUpload";
import {S3File} from "../../../../../models/S3File";


export const AddNewProject = (props: {
    defaultValue?: string
}) => {
    const {payload, handleChange, setValue, setData} = useInputHandler<Project>({
        name: '',
        projectNumber: '',
        projectType: '',
        finishDate: '',
        startDate: '',
        status: '',
        notes: '',
        location: '',
        description: '',
        priority: 'Low',
        clientRequest: [],
        profileImageUrl: ''
    });
    const [project, projectNetworkState, error, postPutProject] = useApiClient<Project[]>();
    const [singleProject, singleProjectNetworkState, errorMessage, getSingleProject] = useApiClient<Project>();
    const toast = useToast();
    const navigate = useNavigate();
    const location = useLocation();
    const [isEdit, setEdit] = useState(useLocation().pathname.endsWith(''));
    const params = useParams<{projectId: string}>();

    useEffect(()=> {
        setEdit(!!params.projectId);
        if (params.projectId) {
            getSingleProject(GetSingleProject(params.projectId))
        }
    }, [params])

    useEffect(() => {
        if (params.projectId) {
            getSingleProject(GetSingleProject(params.projectId)).then(res=> {
                setData({...res, 'profileImageUrl': res.profileImageUrl !== ''?res.profilePicture:null})
            })
        }
    }, [params.projectId]);

    useEffect(() => {
        if (props.defaultValue && props.defaultValue.trim() !== '') {
            setValue(props.defaultValue as keyof Project, {...payload})
        } else {
            // setValue('', '');
        }
    }, [payload])
    const handleDate = (date: string) => {
        const parsedDate = new Date(date).toISOString();
        return parsedDate;
    }
    const onSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (params.projectId) {
            postPutProject(updateProject(payload, params.projectId)).then(res => {
                toast.show({id: v4(), title: "Project Updated Successfully", duration: 2000});
                navigate(-1);
            }).catch(err => {
                if(err.response.status !==403){
                    toast.show({id: v4(), title: "Fail to update", duration: 2000, isSuccess: false});
                }
            });
        } else {
            postPutProject(createNewProject(payload)).then(res => {
                toast.show({id: v4(), title: "New Project Added Successfully", duration: 2000});
                navigate(-1);
            }).catch(err => {
                if(err.response.status !==403){
                    toast.show({id: v4(), title: "Fail to add", duration: 2000, isSuccess: false});}

            })
        }
    }

    const [uploadingFiles, setUploadingFiles] = useState<File[]>([]);
    const [isUploaded, setIsUploading] = useState<boolean>(false);

    const handleUploadSuccess = (res: S3File) => {
        if (res) {
            setValue('profileImageUrl', res.key);
            setIsUploading(false);
        } else {
            setValue('profileImageUrl', '');
            setIsUploading(false);
        }
    };

    const handleFileChange = (file: File | null) => {
        if (file) {
            setUploadingFiles([...uploadingFiles, file]);
            setIsUploading(true);
        }
    };
    return <Fragment>

        <div className={'container p-2'}>
            <div className={`fw-bold col`}>
                <img src={backArrow} className={`me-1 backArrow`}/>
                <small onClick={() => {
                    navigate('/manage')
                }}> Back to projects</small>
            </div>
            <div className={'d-flex pt-5'}>
                <div className={'ms-4 me-4 fw-bold'}>{isEdit ? 'Edit' : 'Add New Project'}</div>
                <div className={'addNewProject d-flex justify-content-center align-items-center ms-4'}>

                    {/*<form id={`form_Project`} className={'flex-fill overflow-y-auto m-4'} onSubmit={(e: any) => {*/}
                    <form id={`form_Project`} className={'flex-fill  m-4'} onSubmit={(e: any) => {
                        onSubmit(e);
                    }}>
                        <div className="form-group mt-2 ">
                            <label className={'small mb-2'}><b>Project Name <span className='text-danger'>*</span></b></label>
                            <input type="text" className="form-control rounded-0" id="exampleInputName"
                                   aria-describedby="nameHelp"
                                   onChange={handleChange} value={payload.name || ''} name="name" required={true}/>

                        </div>
                        <div className="form-group mt-2">
                            <label className={'small mb-2'}><b>Project Number</b></label>
                            <input type={'text'} className="form-control rounded-0" id="exampleInputName"
                                   aria-describedby="nameHelp"
                                   onChange={handleChange} value={payload?.projectNumber} name="projectNumber"/>

                        </div>

                        <div className="form-group mt-2">
                            <label className={'small mb-2'}><b>Location</b></label>
                            <input type={'text'} className="form-control rounded-0" id="exampleInputName"
                                   aria-describedby="nameHelp"
                                   onChange={handleChange} value={payload?.location} name="location"/>


                        </div>



                        <div className="form-group mt-2">
                            <label className={'small'}><b>Project Image</b></label>
                          <UploadFiles defaultImageUrl={payload.profileImageUrl||null} onDone={handleUploadSuccess} onFileChange={handleFileChange}/>
                        </div>

                        <div className="form-group mt-3">
                            <div className={'d-flex justify-content-between row g-3'}>
                                <div className={`col-6`}>
                                    <label className={'small mb-2'}><b>Expected Start Date <span className='text-danger'>*</span></b></label>
                                    <input type={'date'} className="form-control rounded-0 me-2" id="exampleInputName"
                                           aria-describedby="nameHelp"
                                           onChange={handleChange} value={moment(payload?.startDate).format('yyyy-MM-DD')} name="startDate" required/>
                                </div>

                                <div className={`col-6`}>
                                    <label className={'small mb-2'}><b>Expected Finish Date <span className='text-danger'>*</span></b></label>
                                    <input type={'date'} className="form-control rounded-0"
                                           id="exampleInputName"
                                           aria-describedby="nameHelp"
                                        // onChange={handleChange} value={moment(payload.dueDate)} name="dueDate"/>
                                           onChange={handleChange}
                                           value={payload?.finishDate !== '' ? moment(payload?.finishDate).format('yyyy-MM-DD') as any : null}
                                           name="finishDate" required/>
                                </div>
                            </div>
                        </div>


                        <div className="form-group mt-2  border-bottom">
                            <label className={'small mb-2'}><b>Project Status <span className='text-danger'>*</span></b></label>
                            <select className="form-control rounded-0" id="exampleInputName"
                                    aria-describedby="nameHelp"
                                    onChange={handleChange} value={payload.status} name="status" required>
                                <option value={''} disabled><p className={'small'}>Select Status</p></option>
                                <option value={`InProgress`}>In Progress</option>
                                <option value={`Done`}>Done</option>
                            </select>
                        </div>

                        <div className="form-group mt-2 ">
                            <label className={'small mb-2'}><b>Project description</b></label>
                            <textarea rows={4} className="form-control rounded-0" id="exampleInputName"
                                      aria-describedby="nameHelp"
                                      onChange={handleChange} value={payload?.description} name="description"/>
                        </div>


                        <div className="form-group mt-2">
                            <label className={'small mb-2'}><b>Priority</b></label>
                            <select className="form-control rounded-0 row-cols-4" id="exampleInputName"
                                    aria-describedby="nameHelp"
                                    onChange={handleChange} value={payload.priority} name="priority" required style={{
                                backgroundColor: '#FFCCCC', width: '32%', color: '#920000'
                            }}>
                                <option value={''} disabled className={'text-dark'}><small>Select Priority</small>
                                </option>
                                <option value={`Low`}>Low</option>
                                <option value={`Medium`}>Medium</option>
                                <option value={`High`}>High</option>
                            </select>
                        </div>

                        <div className={'d-flex justify-content-center align-item-center mt-2'}>
                            <Button type={'button'}
                                    className={'btn btn-outline-primary py-1 px-3  small button fw-bold m-2'}
                                    onClick={() => navigate(-1)}>Cancel
                            </Button>
                            <Button type={'submit'} loading={projectNetworkState === NetworkState.Loading}
                                    form={`form_Project`} loaderText={`...saving`}
                                    className={'btn btn-primary py-0 px-3  small m-2 button'}
                                // onClick={() =>}
                            >Save</Button>

                        </div>
                    </form>
                </div>
            </div>
        </div>


    </Fragment>
};
export default AddNewProject;